@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caladea:wght@400;700&display=swap');

html {
  padding: 0;
  margin: 0;
}

body {
  --color: #090909;
  --background-color: #fcfcfc;
  --font-size-big: 18px;
  --font-weight-big: 400;
  --line-height-big: 24px;
  --font-size: 18px;
  --font-weight: 400;
  --line-height: 24px;
  --paragraph-margin: 16px;
  padding: 0;
  margin: 0;
  font-family: 'Bitter', serif;
  font-family: 'Caladea', serif;
  line-height: var(--line-height);
  color: var(--color);
  background-color: var(--background-color);
  background: radial-gradient(50% 123.47% at 50% 50%, #D1D1D1 0%, #e2e2e2 100%), linear-gradient(121.28deg, #808080 0%, #B5B5B5 100%), linear-gradient(360deg, #c9a6a6 0%, #919191 100%), radial-gradient(100% 164.72% at 100% 100%, #cabbbb 0%, #D9D9D9 100%), radial-gradient(100% 148.07% at 0% 0%, #F9D1D1 0%, #FFF 100%);
  background-blend-mode: screen, color-dodge, overlay, difference, normal;
}

.App {
  max-width: 760px;
  margin: 0 auto;
  padding: 32px 16px 8px 16px;
}

.Header {
  margin: 0 auto 64px;
  line-height: var(--line-height-big);
  font-size: var(--font-size-big);
  font-weight: var(--font-weight-big);
}

.Logo {
  width: 100%;
  margin: 0 auto 24px;
  text-align: center;
}

.Logo img {
  width: 100%;
}

a {
  color: var(--color);
}

p {
  margin: 0 0 var(--paragraph-margin);
}

@media (min-width: 600px) {
  body {
    --font-size-big: 24px;
    --line-height-big: 28px;
    --paragraph-margin: 24px;
  }
}

@media (min-width: 800px) {
  /* body {
    --font-size-big: 32px;
    --line-height-big: 36px;
    --paragraph-margin: 32px;
  } */
}

@media (min-width: 1200px) {
  /* body {
    --font-size-big: 48px;
    --line-height-big: 52px;
    --paragraph-margin: 32px;
  } */
}