.ProductItem {
  --cover-size: 200px;
  --meta-size: 200px;
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-start;
}

.Cover {
  flex: 0 0 var(--cover-size);
  height: var(--cover-size);
  margin: 0 0 32px;
  box-shadow: 0px 0px 24px #d5d5d5;
}

.Cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Meta {
  padding: 0 0 0 16px;
  width: var(--meta-size);
}

.Title {
  margin: 0;
  font-size: var(--font-size);
  font-weight: 600;
}

.Type {
  margin: 0;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.Year {
  margin: 0 0 16px;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.Links {
  margin: 0;
  padding: 0;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
}

.Links li {
  list-style: none;
}

.Links li a {
  text-decoration: none;
}


@media (min-width: 600px) {
  .ProductItem {
    --cover-size: 350px;
  }
}

@media (min-width: 800px) {
  /* .ProductItem {
    flex: 0 0 calc(50% - 16px);
    padding: 0 16px 0 0;
  } */
}

@media (min-width: 1200px) {
  /* .ProductItem {
    flex: 0 0 calc(33.3% - 16px);
    padding: 0 16px 0 0;
  } */
}